import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  menuSelection: "eFact",
  menuCollapsed: false,
};

export const setMenuSlice = createSlice({
  name: "menuSlice",
  initialState,
  reducers: {
    setMenuSelection: (state, action) => {
      state.menuSelection = action.payload;
    },
    setMenuCollapsed: (state, action) => {
      state.menuCollapsed = action.payload;
    },
  },
});

export const { setMenuSelection, setMenuCollapsed } = setMenuSlice.actions;

export const selectMenuSelection = (state) => state.menuReducer.menuSelection;
export const selectMenuCollapsed = (state) => state.menuReducer.menuCollapsed;

export default setMenuSlice.reducer;
