import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { uniqBy } from 'lodash';

const initialState = {
	alerts: [],
};

const appendAlert = (source, alert) => {
	let alerts = [...source];

	if (!alert.id)
		return alerts;

	return uniqBy([alert, ...alerts], item => item.id)
	// .slice(-6);
};

export const alertSlice = createSlice({
	name: 'alert',
	initialState,
	reducers: {
		addAlert: (state, action) => {
			state.alerts = appendAlert(state.alerts, { ...action.payload, id: action?.payload?.id ?? Date.now() })
		},
		addPersistentAlert: (state, action) => {
			state.alerts = appendAlert(state.alerts, { ...action.payload, id: action?.payload?.id ?? Date.now(), delay: -1 })
		},
		updateAlert: (state, action) => {
			let index = state.alerts.findIndex(alert => alert.id !== action.payload?.id);
			if(index > -1){
				state.alerts.splice(index, 1, action.payload);
			}
		},
		removeAlert: (state, action) => {
			state.alerts = state.alerts.filter(alert => alert.id !== action.payload);
		},
		clearAlerts: (state) => {
			state.alerts = [];
		}
	},
});

export const selectAlerts = (state) => {
	return state.alertReducer.alerts;
}

export const { removeAlert, clearAlerts, addAlert, updateAlert, addPersistentAlert } = alertSlice.actions;

export default alertSlice.reducer;
