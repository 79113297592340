const { useDispatch } = require("react-redux");
const { addAlert, updateAlert, clearAlerts, removeAlert } = require("reducers/alertSlice");

function useNotification() {
  const dispatch = useDispatch();

  const notify = ({ type, title, message, delay = 3, id }) => {

    dispatch(addAlert({
      type,
      message: title,
      description: message,
      delay,
      id: id ?? Date.now()
    }));
  };


  const remove = (id) => dispatch(removeAlert(id));

  const update = (alert) => dispatch(updateAlert(alert));

  const clear = () => dispatch(clearAlerts());

  return { notify, update, clear, remove };
}

export default useNotification;