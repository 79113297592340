import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    currencies: [],    // Array of currency objects with code and rate
    date: "",          // Date when the rates were updated
};

export const currencySlice = createSlice({
    name: "currencySlice",
    initialState,
    reducers: {
        setCurrencyData: (state, action) => {
            const { rates, date } = action.payload;
            state.currencies = rates;
            state.date = date;
        },
    },
});

export const { setCurrencyData } = currencySlice.actions;

// Selector to get all currency data
export const selectCurrencies = (state) => state.currencyReducer.currencies;

// Selector to get the date of the rates
export const selectDate = (state) => state.currencyReducer.date;

export default currencySlice.reducer;
