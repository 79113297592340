import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    subscriptionStatus: null,
};

export const subscriptionStatusSlice = createSlice({
  name: "subscriptionStatus",
  initialState,
  reducers: {
    setSubscriptionStatus: (state, action) => {
      state.subscriptionStatus = action.payload;
    },
    setEndDate: (state, action) => {
      // Verifică dacă action.payload există
      const endDate = action.payload ? action.payload : false;

      // Setează endDate în funcție de existența lui action.payload
      state.subscriptionStatus.consumption.endDate = endDate;
    },
    gracedDate: (state, action) => {
      state.subscriptionStatus.consumption.graceDate = action.payload;
    },
    setHasRequestForPayment: (state, action) => {
      state.subscriptionStatus.consumption.hasRequestForPayment =
        action.payload;
    },
  },
});

// export const selectUser = (state) => {
//     return state.userReducer.user;
// };

export const {
  setSubscriptionStatus,
  setEndDate,
  graceDate,
  setHasRequestForPayment,
} = subscriptionStatusSlice.actions;
export const selectSubscriptionStatus = (state) => { return state?.subscriptionReducer?.subscriptionStatus }

export default subscriptionStatusSlice.reducer;
