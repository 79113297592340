import React, { useEffect } from 'react'
import { styled } from "styled-components";
import HashLoader from "react-spinners/HashLoader";
import { Space } from 'antd';

const Loading = styled('span')`
  font-size: 30px;
  color:#00ff40;
  user-select:none;
  text-align: center;
  align-items:center;
  margin:80px;
  font-weight: bold;
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const LoadingScreen = () => {
  return (
    <Loading
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <HashLoader
        color="#00848B"
        size={100}
        aria-label="Loading Hash Reticule"
        data-testid="loader"
      />
    </Loading>
  );
};
export default LoadingScreen;