
import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { notification, } from 'antd';

import { removeAlert, selectAlerts, addAlert, addPersistentAlert } from "reducers/alertSlice";

function NotificationBar(props) {
    let dispatch = useDispatch();
    let alerts = useSelector(selectAlerts);

    const cancelAlert = useCallback((id) => dispatch(removeAlert(id)), [dispatch]);

    const [api, contextHolder] = notification.useNotification({
        maxCount: 6,
        stack: {
            threshold: 6
        }
    });
    useEffect(() => {
        alerts.forEach(({ type, message, description, delay, id }) => {
            if (!Object.keys(api).includes(type)) {
                // console.error("Invalid type: ", type);
                return;
            }

            api[type]({
                message,
                description,
                duration: delay,
                key: id,
                className: "notification-item",
                style: {
                    width: 600
                },
                onClose: () => cancelAlert(id)
            })
        })
        // console.log("alerts from .", alerts)
    }, [alerts, api, cancelAlert]);

    return contextHolder;
}

export default NotificationBar;