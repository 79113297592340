import { backendClient } from "./clients";

export function getCompanyPartners(company, external = false) {
  return backendClient
    .request({
      url: "/ListCompanyPartner",
      method: "post",
      data: {
        company,
        external,
      },
    })
    .then((res) => res.data)
    .catch((err) => {
      throw new Error("nu exista  parteneri ");
    });
}

export function updateCompanyPartner(company, partner) {
  return backendClient
    .request({
      url: "/UpdateCompanyPartner",
      method: "post",
      data: {
        company,
        partner,
      },
    })
    .then((res) => res.data)
    .catch((err) => err);
}

export function createCompanyPartner(company, partner) {
  return backendClient
    .request({
      url: "/AddCompanyPartner",
      method: "post",
      data: {
        company,
        partner,
      },
    })
    .then((res) => res.data)
    .catch((err) => {
      // console.log("sadasdsadasdasda",err)
      throw err;
    });
}

// .catch((err) => {
//   throw new Error(err.response.data.message);
// });

export function removeCompanyPartner(company, partnerId) {
  return backendClient
    .request({
      url: "/RemoveCompanyPartner",
      method: "post",
      data: {
        company,
        partnerId,
      },
    })
    .then((res) => res.data)
    .catch((err) => err);
}

export function getCompanyProducts(company) {
  return backendClient
    .request({
      url: "/ListCompanyProduct",
      method: "post",
      data: {
        company,
      },
    })
    .then((res) => res.data)
    .catch((err) => err);
}
export function createCompanySerial(company, serial) {
  return new Promise((resolve, reject) => {
    backendClient
      .request({
        url: "/AddCompanySerial",
        method: "post",
        data: {
          company,
          serial,
        },
      })
      .then((res) => resolve(res.data))
      .catch((err) => reject(err?.response?.data?.message?.code));
  });
}

export function updateCompanySerial(companyID, serialDetails) {
  return backendClient
    .request({
      url: "/UpdateCompanySerial",
      method: "post",
      data: {
        companyID,
        serialDetails,
      },
    })
    .then((res) => res.data)
    .catch((err) => err);
}

export function removeCompanySerial(company, serialId) {
  return backendClient
    .request({
      url: "/RemoveCompanySerial",
      method: "post",
      data: {
        company,
        serialId,
      },
    })
    .then((res) => res.data)
    .catch((err) => err);
}

export function getCompanySerials(company) {
  return backendClient
    .request({
      url: "/ListCompanySerial",
      method: "post",
      data: {
        company,
      },
    })
    .then((res) => res.data)
    .catch((err) => err);
}

export function createCompanyVATRates(company, vatRates) {
  return new Promise((resolve, reject) => {
    backendClient
      .request({
        url: "/AddCompanyVatRates",
        method: "post",
        data: {
          company,
          vatRates,
        },
      })
      .then((res) => resolve(res.data))
      .catch((err) => reject(err?.response?.data?.message?.code));
  });
}

export function getCompanyVatRates(company) {
  return backendClient
    .request({
      url: "/ListCompanyVatRates",
      method: "post",
      data: {
        company,
      },
    })
    .then((res) => res.data)
    .catch((err) => err);
}

export function removeCompanyVATRates(company, vatRates) {
  return backendClient
    .request({
      url: "/removeCompanyVATRates",
      method: "post",
      data: {
        company,
        vatRates: vatRates,
      },
    })
    .then((res) => res.data)
    .catch((err) => err);
}

export function updateCompanyProduct(company, id, product) {
  return backendClient
    .request({
      url: "/UpdateCompanyProduct",
      method: "post",
      data: {
        company,
        id,
        product,
      },
    })
    .then((res) => res.data)
    .catch((err) => err);
}

export function createCompanyProduct(company, product) {
  return backendClient
    .request({
      url: "/AddCompanyProduct",
      method: "post",
      data: {
        company,
        product,
      },
    })
    .then((res) => res.data)
    .catch((err) => {
      throw err;
    });
}

export function removeCompanyProduct(company, productId) {
  return backendClient
    .request({
      url: "/RemoveCompanyProduct",
      method: "post",
      data: {
        company,
        productId,
      },
    })
    .then((res) => res.data)
    .catch((err) => err);
}

export function validateCompanyProduct(company, product, strict) {
  return backendClient
    .request({
      url: "/AvailableProduct",
      method: "post",
      data: {
        company,
        product,
        strict,
      },
    })
    .then((res) => res.data);
}

export function sendBugsReport(data, log) {
  return backendClient
    .request({
      url: "/BugsReport",
      method: "post",
      data: {
        data,
        log,
      },
    })
    .then((res) => res.data)
    .catch((err) => err);
}
export function createDraft(company, user, draft) {
  return backendClient
    .request({
      url: "/SaveDraft",
      method: "post",
      data: {
        company,
        user,
        draft,
      },
    })
    .then((res) => res.data)
    .catch((err) => err);
}

export function getCompanyDrafts(company) {
  return backendClient
    .request({
      url: "/ListSavedDrafts",
      method: "post",
      data: {
        company,
      },
    })
    .then((res) => res.data)
    .catch((err) => err);
}

export function removeDraft(company, id) {
  return backendClient
    .request({
      url: "/RemoveCompanyDraft",
      method: "post",
      data: {
        company,
        id,
      },
    })
    .then((res) => res.data)
    .catch((err) => err);
}

export function createRecurrentInvoice(company, user, recurentData) {
  return backendClient
    .request({
      url: "/CreateRecurentInvoice",
      method: "post",
      data: {
        company,
        user,
        recurentData,
      },
    })
    .then((res) => res.data)
    .catch((err) => err);
}

export function updateRecurrentInvoice(
  company,
  recurentID,
  updateData,
  lastSend,
  lastIdIncarcareAnaf,
  lastValue,
  lastSerialNumber
) {
  return backendClient
    .request({
      url: "/UpdateRecurentInvoice",
      method: "post",
      data: {
        company,
        recurentID,
        updateData,
        lastSend,
        lastIdIncarcareAnaf,
        lastValue,
        lastSerialNumber,
      },
    })
    .then((res) => res.data)
    .catch((err) => err);
}

export function getCompanyRecurrents(company) {
  return backendClient
    .request({
      url: "/ListRecurentInvoices",
      method: "post",
      data: {
        company,
      },
    })
    .then((res) => res.data)
    .catch((err) => err);
}

//deoarece este in drafturi folosim tot functia de sters drafturi
export function removeRecurrent(company, id) {
  return backendClient
    .request({
      url: "/RemoveCompanyContracts",
      method: "post",
      data: {
        company,
        id,
      },
    })
    .then((res) => res.data)
    .catch((err) => err);
}

export function addCompanyBankAccount(company, bankAccount) {
  return backendClient
    .request({
      url: "/CreateCompanyBankAccount",
      method: "post",
      data: {
        company,
        bankAccount,
      },
    })
    .then((res) => res.data)
    .catch((err) => {
      throw err;
    });
}

export function updateCompanyBankAccount(companyDto, accountDetails) {
  return backendClient
    .request({
      url: "/UpdateCompanyBankAccount",
      method: "post",
      data: {
        company: companyDto,
        bankAccount: accountDetails,
      },
    })
    .then((res) => res.data)
    .catch((err) => err);
}

export function getCompanyBankAccount(company) {
  return backendClient
    .request({
      url: "/ListBankAccount",
      method: "post",
      data: {
        company,
      },
    })
    .then((res) => res.data)
    .catch((err) => {
      throw new Error("nu exista");
    });
}

export function removeCompanyBankAccount(company, banckId) {
  return backendClient
    .request({
      url: "/RemoveBankAccount",
      method: "post",
      data: {
        company,
        banckId,
      },
    })
    .then((res) => res.data)
    .catch((err) => err);
}

export function getCountAllDataClient(company, userId) {
  return backendClient
    .request({
      url: "/CountAllDataClient",
      method: "post",
      data: {
        company,
        userId,
      },
    })
    .then((res) => res.data)
    .catch((err) => err);
}

export function getAllSubscriptions() {
  return backendClient
    .request({
      url: "GetAllSubscriptionsForUser",
      method: "post",
    })
    .then((res) => res.data)
    .catch((err) => err);
}

export function createCompanySerialDecision(company, data) {
  return backendClient
    .request({
      url: "AddCompanySerialDecision",
      method: "post",
      data: {
        company,
        data,
      },
    })
    .then((res) => res.data)
    .catch((err) => {
      // console.log("not backend", err)
    });
}

export function getAllSerialDecision(company) {
  return backendClient
    .request({
      url: "GetAllSerialDecision",
      method: "post",
      data: {
        company,
      },
    })
    .then((res) => res.data)
    .catch((err) => {
      // console.log(err)
    })
    .finally(() => {
      // console.log("este in finally");
    });
}

export function DownloadAllDataClientDelete(userId) {
  return backendClient
    .request({
      url: "/SaveAllDataClientDeleteAcc",
      method: "post",
      data: {
        userId,
      },
    })
    .then((res) => res.data)
    .catch((err) => err);
}

export function CheckUserSubscriptionStatus() {
  return backendClient
    .request({
      url: "/CheckUserSubscriptionStatus",
      method: "post",
    })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw err;
    });
}

export function createCompanyTransporter(company, transporter) {
  return backendClient
    .request({
      url: "/AddCompanyTransporter",
      method: "post",
      data: {
        company,
        transporter,
      },
    })
    .then((res) => res.data)
    .catch((err) => {
      throw err;
    });
}

export function getCompanyTransporters(company) {
  return backendClient
    .request({
      url: "/ListCompanyTransporter",
      method: "post",
      data: {
        company,
      },
    })
    .then((res) => res.data)
    .catch((err) => err);
}

export function removeCompanyTransporter(company, transporterId) {
  return backendClient
    .request({
      url: "/RemoveCompanyTransporter",
      method: "post",
      data: {
        company,
        transporterId,
      },
    })
    .then((res) => res.data)
    .catch((err) => err);
}


