import { useCallback, useEffect, useState } from "react";
import axios from "axios";
import { useStore } from 'react-redux';

import { unloadUser, loadUser } from "reducers/userSlice";

import { backendClient, reportClient } from "./clients";

function useRequestHandler() {
    const store = useStore();

    let [user, setUser] = useState(null);
    let [company, setCompany] = useState(null);

    const setConfig = useCallback((config) => {
        let { token, keys } = user;

        if (!token)
            delete config.headers['Authorization'];
        else
            config.headers['Authorization'] = `Bearer ${token}`;

        let { backend, efactura, report } = keys ?? {};
        if (!backend)
            delete config.headers["Backend-Key"];
        else
            config.headers["Backend-Key"] = backend;

        if (!efactura)
            delete config.headers["Efactura-Key"];
        else
            config.headers["Efactura-Key"] = efactura;

        if (!report)
            delete config.headers["Report-Key"];
        else
            config.headers["Report-Key"] = report;

        return config;
    }, [JSON.stringify(user)]);

    const storageHandler = useCallback((storage) => {
        let { userReducer: { user: userData }, companyReducer: { currentCompany: companyData } } = storage;
        backendClient.interceptors.request.eject();

        setUser(userData);
        setCompany(companyData);

        // axios.defaults.timeout = 30000;
        axios.defaults.timeout = 0;
    }, []);

    useEffect(() => {
        // console.debug("Subscribed handler");

        let unsubscribe = store.subscribe(() => {
            // console.debug("Data: ", store.getState());
            storageHandler(store.getState());
        });

        // console.debug("InitialData: ", store.getState());
        storageHandler(store.getState());

        return () => {
            // console.debug("Unsubscribed handler");
            unsubscribe()
        };
    }, [store, storageHandler]);

    useEffect(() => {
        // console.trace("Registered user update ", user);
        if (!user) {
            unloadUser();
            return () => { };
        }

        let interceptorId = {
            backend: backendClient.interceptors.request.use(setConfig),
            report: reportClient.interceptors.request.use(setConfig)
        };

        loadUser(user);

        return () => {
            backendClient.interceptors.request.eject(interceptorId.backend);
            reportClient.interceptors.request.eject(interceptorId.report);
        };
    }, [setConfig])

    useEffect(() => {
        // console.debug("Registered company update");
        if (!company)
            return () => { };

        let interceptorId = backendClient.interceptors.request.use((config) => {
            let { subscriptionKey: efactura } = company
            if (!config.headers["Efactura-Key"])
                if (!efactura)
                    delete config.headers["Efactura-Key"];
                else
                    config.headers["Efactura-Key"] = efactura;

            return config;
        });

        return () => backendClient.interceptors.request.eject(interceptorId);
    }, [company]);

    return storageHandler;
}

export default useRequestHandler;