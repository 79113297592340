import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { store } from "./reducers/store";
import App from "./App";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";
import "./index.css";

import { ConfigProvider, theme } from "antd";

const { darkAlgorithm, compactAlgorithm } = theme;
const container = document.getElementById("root");
const root = createRoot(container);

const lightTheme = {
  token: {
    //   colorPrimaryBg: '#0c2d48',
  },
  components: {
    Menu: {
      itemBg: "#001529",
      itemColor: "#ffff",
      itemHoverColor: "#ffff",
      // itemSelectedColor: "red",
      motionDurationSlow: "0.3s",
      // itemSelectedBg: "#1890ff",
    },
    Divider: {
      lineWidth: 2,
    },
    FloatButton: {
      colorBgElevated: null,
      colorText: null,
    },

    // folosim default la butoane in aceasta tema
    // Button: {
    //   colorPrimary: "orange",
    //   colorPrimaryActive: "red",
    // }
  },
};

const anotherTheme = {
  token: {
    colorBgPrimary: "#0c2d48",
    colorBgSider: "#0c2d48",
  },
  components: {
    Menu: {
      colorItemBg: "#0c2d48",
      colorItemText: "#ffff",
      colorItemTextHover: "#ffff",
      colorItemTextSelected: "#fff",
      colorItemTextHoverHorizontal: "red",
      colorItemTextSelectedHorizontal: "orange",
      motionDurationSlow: "1s",
      colorItemBgSelected: "#1890ff",
    },
    Button: {
      colorPrimary: "orange",
      colorPrimaryActive: "red",
    },
  },
};

root.render(
  <React.StrictMode>
    <ConfigProvider theme={lightTheme}>
      <Provider store={store}>
        <App />
      </Provider>
    </ConfigProvider>
  </React.StrictMode>
);

if (process.env.NODE_ENV === "production") {
  serviceWorkerRegistration.register();
}

reportWebVitals(console.debug);

/// theme editor  https://ant.design/theme-editor
