function Storage(){
    this.store = window.sessionStorage;
    this.get = (id) => {
        return this.store.getItem(id);
    };
    this.put = (id, value) => {
        this.store.setItem(id, value);
    };
    this.clear = () => {
        this.store.clear();
    };
}

function useStorage() {
    return new Storage();
}

export {Storage};

export default useStorage;